import React, { useState } from 'react';
import logo from './images/logo.png';

const LandingPage = () => {
  const [userType, setUserType] = useState('dentist');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsSubmitting(true);
    
    const formData = {
      userType,
      name,
      email
    };

    try {
      console.log('Submitting form data:', formData);
      const response = await fetch('https://emaildelivery.reallyethical.workers.dev/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      
      console.log('Response status:', response.status);
      
      if (!response.ok) {
        const errorData = await response.text();
        console.error('Error response:', errorData);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Response data:', data);
      
      setSubmitted(true);
    } catch (err) {
      console.error('Submission error:', err);
      setError(`An error occurred: ${err.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-blue-100 flex flex-col items-center justify-center p-4">
      <div className="w-full max-w-md bg-white rounded-lg shadow-md p-6">
        <div className="mb-6">
          <a href="https://retainers4u.com" className="flex items-center justify-center mb-4">
            <img src={logo} alt="Retainers4U logo" className="w-[34px] h-[36px] mr-2" />
            <span className="text-2xl font-bold">Retainers4U</span>
          </a>
          <p className="text-center text-gray-600">
            Please register your interest in Retainers4U below. Our full site will be launching soon.
          </p>
        </div>

        {submitted ? (
          <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4" role="alert">
            <p className="font-bold">Thank you for your interest!</p>
            <p>We'll be in touch soon.</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="flex justify-center space-x-4">
              <button
                type="button"
                onClick={() => setUserType('dentist')}
                className={`px-4 py-2 rounded ${
                  userType === 'dentist' ? 'bg-blue-500 text-white' : 'bg-gray-200'
                }`}
              >
                I am a Dentist
              </button>
              <button
                type="button"
                onClick={() => setUserType('patient')}
                className={`px-4 py-2 rounded ${
                  userType === 'patient' ? 'bg-blue-500 text-white' : 'bg-gray-200'
                }`}
              >
                I am a Patient
              </button>
            </div>
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-2 border rounded"
              required
            />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-2 border rounded"
              required
            />
            <button
              type="submit"
              className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 disabled:bg-blue-300"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
            {error && <p className="text-red-500 text-sm">{error}</p>}
          </form>
        )}
      </div>
    </div>
  );
};

export default LandingPage;